import React from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import neeti from "../../assets/images/profile/neeti.png";
const data = {
  name: "Ms Neeti Nagarkar",
  imageurl: "/assets/images/profile/neeti.png",
  description:
    "Having over 2 decades of experience in the field of early childhood education, Neeti, is the Founder of Kids World Education Consultants. She holds a master’s degree from The University of Pune (Maharashtra) and has completed Early Childhood Care and Education (ECCE) course from ORT India (Mumbai). Neeti is an accredited Professional Trainer from Jolly Learning Ltd. (UK) for Jolly Phonics & Jolly Grammar. Also, she is a certified Consultant for Handwriting Without Tears (USA). Neeti is now the Delivery Partner in India for Jolly Futures projects for government schools. Neeti has worked as a pre-school teacher for about 10 years and as the Academic Head for a chain of pre-schools in Mumbai. She has been working as a faculty for ECCE course and is the In-charge of the Nursery Teachers’ Training Course (affiliated to All-India ECCE) conducted at her centre. She was honoured with the opportunity to deliver the keynote speech and share her vast experience, in an event to promote early childhood education in Azerbaijan.Additionally, Neeti is the President and one of the Founder Directors for International Teachers Guild Foundation (Mumbai), a registered non-profit organization, with the aim of empowering teachers and connecting with children through education. Cradle & Swings Preschool at Andheri East, Mumbai is the new set-up as a joint venture of KWEC and Hi 5 CDC. The center also offers Nursery Teachers Training Course (one year diploma)",
};

const TeachersDetailsPage = () => {
  return (
    <Layout pageTitle="KWEC | Teachers Details">
      <Navbar />
      <PageHeader title="Our Team" />
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={neeti} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    {data.name}
                  </h2>
                  <p className="team-one__designation">Founder & Teacher Trainer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-details__content">
                <h2 className="team-details__title">Read my story</h2>
                <p className="team-details__text">{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TeachersDetailsPage;
